<template>
  <div class="auth auth--row register">
    <div class="register__content">
      <div class="logo">
        <img :src="require(`@/assets/images/logo.png`)" alt="logo" />
      </div>
      <ValidationObserver v-slot="{ invalid }" tag="section" class="form">
        <h1>Register</h1>
        <div class="form__row">
          <div class="form__row_col">
            <BaseInput
              :required="true"
              placeholder="Email"
              :disabled="true"
              v-model="user.email"
              :element="user.email"
            ></BaseInput>
          </div>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="First Name"
              :required="true"
              :validateError="errors[0]"
              v-model="user.first_name"
              :element="user.first_name"
              @input="user.first_name = $event"
            ></BaseInput>
          </ValidationProvider>
          <ValidationProvider
            tag="div"
            class="form__row_col form__row_col--side"
            :rules="{ required: true, min: 2, max: 255 }"
            v-slot="{ errors }"
          >
            <BaseInput
              placeholder="Last Name"
              :required="true"
              :validateError="errors[0]"
              v-model="user.last_name"
              :element="user.last_name"
              @input="user.last_name = $event"
            ></BaseInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            :rules="{ verify_password: true }"
            v-slot="{ errors }"
            name="password"
          >
            <PasswordInput
              :required="true"
              placeholder="Set password"
              type="password"
              name="password"
              :validateError="errors[0]"
              v-model="user.password"
              :element="user.password"
              @input="user.password = $event"
            ></PasswordInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <ValidationProvider
            tag="div"
            class="form__row_col"
            rules="required|confirmed:password|min: 8|max: 255"
            v-slot="{ errors, valid }"
          >
            <PasswordInput
              :required="true"
              :class="{ 'form-control--valid': valid }"
              placeholder="Confirm password"
              type="password"
              :validateError="errors[0]"
              v-model="user.password_confirmation"
              :element="user.password_confirmation"
              @input="user.password_confirmation = $event"
            ></PasswordInput>
          </ValidationProvider>
        </div>
        <div class="form__row">
          <BaseButton
            text="Register"
            class="button--full-width button--black button--uppercase"
            :disabled="invalid"
            :loading="loader"
            @click="register"
          ></BaseButton>
        </div>
      </ValidationObserver>
      <div class="auth__footer">
        <p>© Collekt 2022</p>
        <ul>
          <li><router-link to="/terms-and-conditions">Terms & Conditions</router-link></li>
          <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
        </ul>
      </div>
    </div>
    <div class="register__image"></div>
  </div>
</template>
<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import BaseInput from "@/components/inputs/BaseInput.vue";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseCheckbox from "@/components/inputs/BaseCheckbox.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BaseInput,
    PasswordInput,
    BaseButton,
    BaseCheckbox,
  },
  data() {
    return {
      user: {
        email: "",
        first_name: "",
        last_name: "",
        password: "",
        password_confirmation: "",
      },
    };
  },
  created() {
    this.user.email = this.$route.query.email;
  },
  computed: {
    ...mapGetters("auth", { loader: "getLoading" }),
  },
  methods: {
    ...mapActions("auth", ["registerUser"]),
    async register() {
      let result = await this.registerUser(this.user);
    },
  },
};
</script>
<style lang="scss" scoped>
.register {
  display: flex;
  height: 100vh;
  padding: 15px 15px;
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 42%;
    height: 100%;
    margin-right: 5rem;
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      width: 100%;
      margin-right: 0;
    }
    .logo {
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        width: 100px;
        margin: 0 auto;
      }
    }
    .form {
      width: 100%;
      flex: 1;
      padding: 2.5rem 11rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media only screen and (max-width: $lg) {
        padding: 20px 5rem;
      }
      @media only screen and (max-width: $md) {
        padding: 20px 0;
      }
      @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
        max-width: 50rem;
        margin: 0 auto;
        padding: 10px 0 20px;
      }
      h1 {
        font-size: 4.4rem;
        font-weight: 500;
        line-height: 1.34;
        margin-bottom: 2rem;
        @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
          font-size: 2.5rem;
          text-align: center;
        }
      }
      .button {
        margin-top: 1.3rem;
      }
    }
  }
  &__image {
    width: 58%;
    max-width: 105rem;
    margin: 0 0 0 auto;
    height: 100%;
    border-radius: 14px;
    background-image: url("~@/assets/images/login.png");
    background-position: center center;
    background-size: cover;
    @media only screen and (max-width: $md) {
      max-height: 500px;
      margin: auto;
    }
    @media (max-height: 499px) and (max-width: 899px), (max-width: $sm) {
      display: none;
    }
  }
}
</style>
